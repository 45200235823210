import Axios from "axios";
import React, { useState, Fragment, useEffect } from "react";
import "./AddWeight.css";
import appsettings from "../appsettings";
import { ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import { onError } from "../libs/errorLib";
import LoaderButton from "../components/LoaderButton";
import { useHistory } from "react-router-dom";

function AddWeight() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [weight, setWeight] = useState("");
  const [newkg, setNewkg] = useState(0);

  useEffect(() => {
    async function get() {
      try {
        const { data } = await Axios.get(
          `${appsettings.apiUrl()}/api/bodyweight`
        );
        console.log(data);
        setWeight(data);
      } catch (err) {
        console.log(err);
        onError(err);
      }

      setIsLoading(false);
    }
    get();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      const { data } = await Axios.post(
        `${appsettings.apiUrl()}/api/bodyweight`,
        {
          kg: parseFloat(newkg),
        }
      );
      console.log(data);
      alert("Bodyweight Updated");
      history.push("/dashboard");
    } catch (e) {
      setIsLoading(false);
      onError(e);
    }
    setIsLoading(false);
  }

  if (isLoading) {
    return "...";
  }
  return (
    <>
      <div className="header"></div>
      <div className="circleinfo">
        <div className="ratio">{weight.currentWeight.kg}</div>
        <div className="date">kg</div>
      </div>

      <div className="add-kg">
        <form onSubmit={handleSubmit}>
          <FormGroup controlId="newkg" bsSize="large">
            <ControlLabel>Update Body Weight (kgs)</ControlLabel>
            <FormControl
              autoFocus
              type="decimal"
              value={newkg}
              onChange={(e) => {
                setNewkg(e.target.value);
              }}
            />
          </FormGroup>

          <LoaderButton
            block
            type="submit"
            bsSize="large"
            isLoading={isLoading}
            disabled={newkg < 40}
          >
            Save
          </LoaderButton>
        </form>
      </div>

      <div className="bw-history">
        <h2>Body Weight History</h2>
        {weight.history.map((i, key) => {
          return (
            <p key={key}>
              <strong>{i.kg}</strong> kg on{" "}
              {new Date(i.weighedOn).toLocaleDateString()}
            </p>
          );
        })}
      </div>
    </>
  );
}

export default AddWeight;
