import React, { useState } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import { useAppContext } from "../libs/contextLib";
import axios from 'axios';
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import appsettings from "../appsettings";

export default function Login() {
    const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);
    const [fields, handleFieldChange] = useFormFields({
        username: "",
        email: "",
        password: ""
    });

    function validateForm() {
        return fields.username.length > 0 && fields.password.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            const { data } = await axios.post(`${appsettings.apiUrl()}/api/Authenticate/login`, {
                username: fields.username,
                password: fields.password
            });
            localStorage.setItem('lr.token', data.token);
            localStorage.setItem('lr.tokenexpires', data.expiration);
            userHasAuthenticated(true);

        } catch (e) {
            setIsLoading(false);
            console.log(e);
            onError(e);
        }
    }

    return (

        <div id="container">
            <div className="Login">
                <form onSubmit={handleSubmit}>
                    <FormGroup controlId="username" bsSize="large">
                        <ControlLabel>Username</ControlLabel>
                        <FormControl
                            autoFocus
                            type="text"
                            value={fields.username}
                            onChange={handleFieldChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="password" bsSize="large">
                        <ControlLabel>Password</ControlLabel>
                        <FormControl
                            value={fields.password}
                            onChange={handleFieldChange}
                            type="password"
                        />
                    </FormGroup>
                    <LoaderButton
                        block
                        type="submit"
                        bsSize="large"
                        isLoading={isLoading}
                        disabled={!validateForm()}>
                        Login
                        </LoaderButton>
                </form>
            </div>
        </div>
    );
}