const appsettings = {
    apiUrlDev : 'https://localhost:44329',
    apiUrlStage: 'https://liftratioapi.1cctech.net',

    apiEnv: 'stage',

    apiUrl: () => {
        if (appsettings.apiEnv === "stage") {
            return appsettings.apiUrlStage;
        }
        return appsettings.apiUrlDev;
    }



}

export default appsettings;