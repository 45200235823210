import Axios from "axios";
import React, { useState, Fragment, useEffect } from "react";
import "./Dashboard.css";
import appsettings from "../appsettings";
import { onError } from "../libs/errorLib";

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboard, setDashboard] = useState({});

  useEffect(() => {
    async function get() {
      try {
        const { data } = await Axios.get(
          `${appsettings.apiUrl()}/api/dashboard`
        );
        console.log(data);
        setDashboard(data);
      } catch (err) {
        onError(err);
        console.log(err);
        console.log(err.message);
      }

      setIsLoading(false);
    }
    get();
  }, []);

  if (isLoading) {
    return "...";
  }

  return (
    <Fragment>
      <div className="header"></div>
      <div className="ratioholder">
        <div className="circleinfo">
          <div className="ratio">{dashboard.mySingleRatio}</div>
          <div className="date">
            Overall Single Rep<br />
            Lift Ratio
          </div>
        </div>
        <div className="circleinfo">
          <div className="ratio">{dashboard.myMultiRatio}</div>
          <div className="date">
            Overall Multi Rep <br />
            Lift Ratio
          </div>
        </div>
      </div>
      <div className="exercisecard">
        <div className="liftmain">
          <div className="sub">Latest Deadlift</div>
          {dashboard && dashboard.deadlift ? (
            <>
              <div className="cardratio gradient">
                {dashboard.deadlift.ratio}
              </div>
              <div className="cardweight">{dashboard.deadlift.kg + "kg"} </div>
              <div className="carddate">
                {new Date(dashboard.deadlift.liftedOn).toLocaleDateString()}
              </div>
              <div className="singleOrMulti">{dashboard.deadlift.reps > 1 ? "M" : "S"}</div>
            </>
          ) : (
            <>No deadlift set</>
          )}
        </div>
        <div className="ratios">
          Best Single lift Ratio: <br />
          <span className="gradient ratio">{dashboard.bestSingleDeadlift?.ratio}</span>
          <br />
          <br />
          Best Multilift Ratio: <br />
          <span className="gradient ratio">{dashboard.bestMultiDeadlift?.ratio}</span>
        </div>
      </div>

      <div className="exercisecard">
        <div className="liftmain">
          <div className="sub">Latest Squat</div>
          {dashboard && dashboard.squat ? (
            <>
              <div className="cardratio gradient">{dashboard.squat.ratio}</div>
              <div className="cardweight">{dashboard.squat.kg + "kg"} </div>
              <div className="carddate">
                {new Date(dashboard.squat.liftedOn).toLocaleDateString()}
              </div>
              <div className="singleOrMulti">{dashboard.squat.reps > 1 ? "M" : "S"}</div>
            </>
          ) : (
            <>No deadlift set</>
          )}
        </div>
        <div className="ratios">
          Best Single lift Ratio: <br />
          <span className="gradient ratio">{dashboard.bestSingleSquat?.ratio}</span>
          <br />
          <br />
          Best Multilift Ratio: <br />
          <span className="gradient ratio">{dashboard.bestMultiSquat?.ratio}</span>
        </div>
      </div>
      <div className="exercisecard">
        <div className="liftmain">
          <div className="sub">Latest Bench Press</div>
          {dashboard && dashboard.benchPress ? (
            <>
              <div className="cardratio gradient">
                {dashboard.benchPress.ratio}
              </div>
              <div className="cardweight">
                {dashboard.benchPress.kg + "kg"}{" "}
              </div>
              <div className="carddate">
                {new Date(dashboard.benchPress.liftedOn).toLocaleDateString()}
              </div>
              <div className="singleOrMulti">{dashboard.benchPress.reps > 1 ? "M" : "S"}</div>
            </>
          ) : (
            <>No deadlift set</>
          )}
        </div>
        <div className="ratios">
          Best Single lift Ratio: <br />
          <span className="gradient ratio">{dashboard.bestSingleBenchPress?.ratio}</span>
          <br />
          <br />
          Best Multilift Ratio: <br />
          <span className="gradient ratio">{dashboard.bestMultiBenchPress?.ratio}</span>
        </div>
      </div>
      <div className="exercisecard">
        <div className="liftmain">
          <div className="sub">Latest Overhead Lift</div>
          {dashboard && dashboard.overhead ? (
            <>
              <div className="cardratio gradient">
                {dashboard.overhead.ratio}
              </div>
              <div className="cardweight">{dashboard.overhead.kg + "kg"} </div>
              <div className="carddate">
                {new Date(dashboard.overhead.liftedOn).toLocaleDateString()}
              </div>
              <div className="singleOrMulti">{dashboard.overhead.reps > 1 ? "M" : "S"}</div>
            </>
          ) : (
            <>No deadlift set</>
          )}
        </div>
        <div className="ratios">
          Best Single lift Ratio: <br />
          <span className="gradient ratio">{dashboard.bestSingleOverhead?.ratio}</span>
          <br />
          <br />
          Best Multilift Ratio: <br />
          <span className="gradient ratio">{dashboard.bestMultiOverhead?.ratio}</span>
        </div>
      </div>

      <div className="fact gradientbg">
        <h3>Top lifts of all time</h3>
        <p>
          The current world record "raw" bench press (without a bench shirt) is
          335.5 kg / 739.6 lb, set by Julius Maddox Of Owensboro, KY, USA on
          September 1, 2019.
        </p>
      </div>

      <div className="leaderboardcontainer">
        <div className="leaderboardheader">
          <h3>Leaderboards</h3>

          <select name="exercise" id="exercise" className="exerciseselect">
            <option value="Bench press">Bench Press</option>
            <option value="Deadlift">Deadlift</option>
            <option value="Overhead Press">Overhead Press</option>
            <option value="Squat">Squat</option>
          </select>
        </div>
        <div className="user">
          <span className="pos">01</span>
          <span className="uname">Power9000</span>
          <span className="ratio gradient">1:956</span>
        </div>
        <div className="user">
          <span className="pos">02</span>
          <span className="uname">Power9000</span>
          <span className="ratio gradient">1:953</span>
        </div>
        <div className="user">
          <span className="pos">03</span>
          <span className="uname">Power9000</span>
          <span className="ratio gradient">1:952</span>
        </div>
        <div className="user">
          <span className="pos">04</span>
          <span className="uname">Power9000</span>
          <span className="ratio gradient">1:950</span>
        </div>
        <div className="user">
          <span className="pos">05</span>
          <span className="uname">Power9000</span>
          <span className="ratio gradient">1:931</span>
        </div>
        <div className="user">
          <span className="pos">06</span>
          <span className="uname">Power9000</span>
          <span className="ratio gradient">1:904</span>
        </div>
        <div className="user">
          <span className="pos">07</span>
          <span className="uname">Power9000</span>
          <span className="ratio gradient">1:871</span>
        </div>
        <div className="user">
          <span className="pos">08</span>
          <span className="uname">Power9000</span>
          <span className="ratio gradient">1:860</span>
        </div>
        <div className="view gradient">View Leaderboards</div>
      </div>
    </Fragment>
  );
}
