import Axios from "axios";
import React, { useState, Fragment, useEffect } from "react";
import "./Leaderboard.css";
import appsettings from "../appsettings";

export default function Leaderboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboard, setDashboard] = useState({});

  console.log(dashboard);

  useEffect(() => {
    async function get() {
      try {
        const { data } = await Axios.get(
          `${appsettings.apiUrl()}/api/dashboard`
        );
        console.log(data);
        setDashboard(data);
      } catch (err) {
        console.log(err);
        console.log(err.message);
      }

      setIsLoading(false);
    }
    get();
  }, []);

  if (isLoading) {
    return "...";
  }

  return (
    <Fragment>
      <div className="header">
        <h1 className="pageheader">Leaderboards</h1>
        <div className="leadboardranking">
          <div className="leaderboardratio">
            <strong>Ratio</strong>
            <br />
            1.9
          </div>
          <div className="leaderboardratio">
            <strong>Place</strong>
            <br />
            19
          </div>
        </div>
      </div>
      <div className="leaderboardcontainer">
        <div className="leaderboardheader">
          <select name="exercise" id="exercise" className="exerciseselect">
            <option value="Bench press">Daily</option>
            <option value="Deadlift">Weekly</option>
            <option value="Overhead Press">Monthly</option>
            <option value="Squat">Overall</option>
          </select>
          <select name="exercise" id="exercise" className="exerciseselect">
            <option value="Bench press">Bench Press</option>
            <option value="Deadlift">Deadlift</option>
            <option value="Overhead Press">Overhead Press</option>
            <option value="Squat">Squat</option>
          </select>
        </div>
        <div className="user">
          <span className="pos">01</span>
          <span className="uname">Power9000</span>
          <span className="ratio gradient">1:956</span>
        </div>
        <div className="user">
          <span className="pos">02</span>
          <span className="uname">Power9000</span>
          <span className="ratio gradient">1:953</span>
        </div>
        <div className="user">
          <span className="pos">03</span>
          <span className="uname">Power9000</span>
          <span className="ratio gradient">1:952</span>
        </div>
        <div className="user">
          <span className="pos">04</span>
          <span className="uname">Power9000</span>
          <span className="ratio gradient">1:950</span>
        </div>
        <div className="user">
          <span className="pos">05</span>
          <span className="uname">Power9000</span>
          <span className="ratio gradient">1:931</span>
        </div>
        <div className="user">
          <span className="pos">06</span>
          <span className="uname">Power9000</span>
          <span className="ratio gradient">1:904</span>
        </div>
        <div className="user">
          <span className="pos">07</span>
          <span className="uname">Power9000</span>
          <span className="ratio gradient">1:871</span>
        </div>
        <div className="user">
          <span className="pos">08</span>
          <span className="uname">Power9000</span>
          <span className="ratio gradient">1:860</span>
        </div>
        <div className="view gradient">View Leaderboards</div>
      </div>
    </Fragment>
  );
}
