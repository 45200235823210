import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import FB from "./images/icons/fb.svg";
import Google from "./images/icons/google.svg";
import Twitter from "./images/icons/twitter.svg";
import { Fragment } from "react";

export default function Home() {
  return (
    <Fragment>

      <div id="container">
        <span className="sub">Welcome to</span>
        <h1 className="large">
          LIFT <br />
          RATIO
        </h1>
        <h2 className="gradient">
          Create an account <br />
          and start tracking...
        </h2>
        <br />
        Get Started with
        <br />
        <div className="socialbuttons">
          <button className="social">
            <img alt="img" src={FB} />
          </button>
          <button className="social">
            <img alt="img" src={Google} />
          </button>
          <button className="social">
            <img alt="img" src={Twitter} />
          </button>
        </div>
        <br />
        Or sign up with your
        <br />
        <Link to="/signup">
          <button className="gradientbg sixty">Email address</button>
        </Link>
        <br />
        <Link to="/login">Already a member? Login</Link>
      </div>
    </Fragment>
  );
}
