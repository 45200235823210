export function onError(error) {
    console.log(error);
    let message = error.toString();
    let supressAlert = false;

    if(message === "Error: Request failed with status code 401"){
        message = "Bad username or password";
        localStorage.clear();
        window.location.reload();
    }
    
    
    if(message === "Error: Request failed with status code 500"){
        message = error.response.data.message;
    }

    if(error.status === 404){
        message = "Page Not Found";
    }
    if(error.status === 400){
        message = error.data.message;
    }
    if(error.status === 401){
        supressAlert = true;
        localStorage.clear();
        window.location.reload();
    }

    // Auth errors
    if (!(error instanceof Error) && error.message) {
        message = error.message;
    }

    if(message === "")
    {
        message = "Something went wrong...";
    }
    
    if(supressAlert === false){
        alert(message);
    }
}