import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
    FormGroup,
    FormControl,
    ControlLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import axios from 'axios';
import { onError } from "../libs/errorLib";
import "./Signup.css";
import appsettings from "../appsettings";

export default function Signup() {
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        username: "",
        password: "",
        confirmPassword: "",
        confirmationCode: "",
    });
    const history = useHistory();
    const [newUser, setNewUser] = useState(null);
    const { userHasAuthenticated } = useAppContext();
    const [isLoading, setIsLoading] = useState(false);

    function validateForm() {
        return (
            fields.username.length > 0 &&
            fields.email.length > 0 &&
            fields.password.length > 0 &&
            fields.password === fields.confirmPassword
        );
    }

    function validateConfirmationForm() {
        return fields.confirmationCode.length >= 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            const newUser = await axios.post(`${appsettings.apiUrl()}/api/Authenticate/register`, {
                username: fields.username,
                email: fields.email,
                password: fields.password,
                name: fields.name,
                dob: fields.dob,
                postcode: fields.postcode
            });
            console.log(newUser);
            setIsLoading(false);
            setNewUser(newUser);
        } catch (e) {
            onError(e.response.data.message);
            setIsLoading(false);
        }
    }

    async function handleConfirmationSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        try {
            const { data } = await axios.post(`${appsettings.apiUrl()}/api/Authenticate/login`, {
                username: fields.username,
                password: fields.password
            });
            console.log(data);
            localStorage.setItem('token', data.token);
            userHasAuthenticated(true);
            history.push("/");
        } catch (e) {
            setIsLoading(false);
            onError(e);
        }

    }

    function renderConfirmationForm() {
        return (
            <form onSubmit={handleConfirmationSubmit}>
                <p className="center">
                    Thank you for joining!
                </p>
                {/* <FormGroup controlId="confirmationCode" bsSize="large">
                    <ControlLabel>Confirmation Code</ControlLabel>
                    <FormControl
                        autoFocus
                        type="tel"
                        onChange={handleFieldChange}
                        value={fields.confirmationCode}
                    />
                    <HelpBlock>Please check your email for the code.</HelpBlock>
                </FormGroup> */}
                <LoaderButton
                    block
                    type="submit"
                    bsSize="large"
                    isLoading={isLoading}
                    disabled={!validateConfirmationForm()}
                >
                    Enter LiftRatio
        </LoaderButton>
            </form>
        );
    }

    function renderForm() {
        return (
            <div id="container">
              <span className="sub">
                Welcome to
            </span>
            <h1>LIFT <br/>RATIO</h1>
            <h2 className="gradient">
                Sign up to continue
            </h2>
                <form onSubmit={handleSubmit}>
                    <FormGroup controlId="email" bsSize="large">
                        <ControlLabel>Email</ControlLabel>
                        <FormControl
                            autoFocus
                            type="email"
                            value={fields.email}
                            onChange={handleFieldChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="username" bsSize="large">
                        <ControlLabel>Username</ControlLabel>
                        <FormControl
                            type="username"
                            value={fields.username}
                            onChange={handleFieldChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="password" bsSize="large">
                        <ControlLabel>Password</ControlLabel>
                        <FormControl
                            type="password"
                            value={fields.password}
                            onChange={handleFieldChange}
                        />
                    </FormGroup>
                    <FormGroup controlId="confirmPassword" bsSize="large">
                        <ControlLabel>Confirm Password</ControlLabel>
                        <FormControl
                            type="password"
                            onChange={handleFieldChange}
                            value={fields.confirmPassword}
                        />
                    </FormGroup>

                    
                    <FormGroup controlId="name" bsSize="large">
                        <ControlLabel>Name</ControlLabel>
                        <FormControl
                            type="text"
                            onChange={handleFieldChange}
                            value={fields.name}
                        />
                    </FormGroup>
                    <FormGroup controlId="dob" bsSize="large">
                        <ControlLabel>Date of Birth</ControlLabel>
                        <FormControl
                            type="date"
                            onChange={handleFieldChange}
                            value={fields.dob}
                        />
                    </FormGroup>
                    

                    <LoaderButton
                        block
                        type="submit"
                        bsSize="large"
                        isLoading={isLoading}
                        disabled={!validateForm()}
                    >
                        Signup
        </LoaderButton>
                </form>
            </div>
        );
    }

    return (
        <div className="Signup">
            {newUser === null ? renderForm() : renderConfirmationForm()}
        </div>
    );
}