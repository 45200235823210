import React from "react";
import "./NotFound.css";
import AddWeight from "./AddWeight";

export default function AddFirstWeight() {
  return (
    <div className="first-weight">
      <h3>Please record your body weight</h3>

      <AddWeight></AddWeight>
    </div>
  );
}
