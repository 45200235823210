import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import TestSecureEndpoint from './components/tests/TestSecureEndpoint';
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Dashboard from "./containers/Dashboard";
import Signup from "./containers/Signup";
import Leaderboard from "./containers/Leaderboard";
import Record from "./containers/Record";
import AuthenticatedRoute from "./components/AuthenticatedRoute"
import UnauthenticatedRoute from "./components/UnauthenticatedRoute"
import AddWeight from "./containers/AddWeight";
import RecordActivity from "./containers/RecordActivity";
import AddFirstWeight from "./containers/AddFirstWeight";

export default function Routes() {
    return (
        <Switch>
            <UnauthenticatedRoute exact path="/">
                <Home />
            </UnauthenticatedRoute>

            <Route exact path="/test">
                <TestSecureEndpoint />
            </Route>

            <AuthenticatedRoute exact path="/dashboard">
                <Dashboard />
            </AuthenticatedRoute>


            <AuthenticatedRoute exact path="/first-weight">
                <AddFirstWeight />
            </AuthenticatedRoute>            
            <AuthenticatedRoute exact path="/add-weight">
                <AddWeight />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/leaderboard">
                <Leaderboard />
            </AuthenticatedRoute>
            
            <AuthenticatedRoute exact path="/recordactivity">
                <RecordActivity />
            </AuthenticatedRoute>

            <AuthenticatedRoute exact path="/record/:activity">
                <Record />
            </AuthenticatedRoute>



            <UnauthenticatedRoute  exact path="/login">
                <Login />
            </UnauthenticatedRoute >

            <UnauthenticatedRoute  exact path="/signup">
                <Signup />
            </UnauthenticatedRoute >


            {/* Finally, catch all unmatched routes */}
            <Route>
                <NotFound />
            </Route>
        </Switch>
    );
}