import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import "./RecordActivity.css";
import Deadlift from "./images/icons/deadlift.svg";
import Squat from "./images/icons/squat.svg";
import OHP from "./images/icons/ohp.svg";
import Bench from "./images/icons/bench.svg";

export default function RecordActivity() {
  return (
    <Fragment>
      <div className="header">
        <h1 className="pageheader">Select an Activity below</h1>
      </div>
      <div className="select-activity">
        <Link to="/record/deadlift">
          <div className="liftcard">
            <div className="cardleft">
              <h2>Deadlift</h2>
            </div>
            <img alt="img" src={Deadlift} />
          </div>
        </Link>
        <Link to="/record/overhead">
          <div className="liftcard">
            <div className="cardleft">
              <h2>Overhead Press</h2>
            </div>
            <img alt="img" src={OHP} />
          </div>
        </Link>
        <Link to="/record/benchpress">
          <div className="liftcard">
            <div className="cardleft">
              <h2>Benchpress</h2>
            </div>
            <img alt="img" src={Bench} />
          </div>
        </Link>
        <Link to="/record/squat">
          <div className="liftcard">
            <div className="cardleft">
              <h2>Squat</h2>
            </div>
            <img alt="img" src={Squat} />
          </div>
        </Link>
      </div>
    </Fragment>
  );
}
