import React, { useState, useEffect } from "react";
import "./App.css";
import Routes from "./Routes";
import { Link } from "react-router-dom";
import { NavItem } from "react-bootstrap";
import { AppContext } from "./libs/contextLib";
import { LinkContainer } from "react-router-bootstrap";
import Logo from "./containers/images/logo.svg";
import Dashboard from "./containers/images/icons/Dashboard.svg";
import Leaderboards from "./containers/images/icons/Leaderboards.svg";
import Logout from "./containers/images/icons/logout.svg";
import ProfilePic from "./containers/images/squat.jpg";

function App() {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  
  const storedJwt = localStorage.getItem("lr.token");
  const [jwt] = useState(storedJwt || null);

  useEffect(() => {
    const checkJwtStatus = () => {
      console.log(jwt);
      if (jwt) {
        // check the expiry of the token
        let expiry = new Date(localStorage.getItem("lr.tokenexpires"))
        if (expiry > new Date())
          userHasAuthenticated(true);
      }
    };

    checkJwtStatus();
  }, []);

  function handleLogout() {
    localStorage.clear();
    userHasAuthenticated(false);
  }

  /* Set the width of the side navigation to 250px and the left margin of the page content to 250px and add a black background color to body */
  function openNav() {
    document.getElementById("navigation").style.width = "100%";
    document.getElementById("navigation").style.display = "flex";
  }

  /* Set the width of the side navigation to 0 and the left margin of the page content to 0, and the background color of body to white */
  function closeNav() {
    document.getElementById("navigation").style.width = "0";
  }

  return (
    <>
      <div id="navigation">
        <div className="close gradient">
          <NavItem onClick={closeNav}>X</NavItem>
        </div>
        <div className="navprofile">
          <img className="profilepic" src={ProfilePic} alt="profile" />
          <div className="profilename">
            <span className="nicename">Sean Williams</span>
            <br />
            sean_williams202
          </div>
        </div>
        <div className="navmenu">
          <ul>
            <LinkContainer to="/dashboard">
              <NavItem onClick={closeNav}>
                <img alt="img" src={Dashboard} />
                Dashboard
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/add-weight">
              <NavItem onClick={closeNav}>
                <img alt="img" src={Dashboard} />
                Update Bodyweight
              </NavItem>
            </LinkContainer>

            <LinkContainer to="/leaderboard">
              <NavItem onClick={closeNav}>
                <img alt="img" src={Leaderboards} />
                Leaderboards
              </NavItem>
            </LinkContainer>
            {/* <LinkContainer to="/profile">
              <NavItem onClick={closeNav}>
                <img alt="img" src={Profile} />
                Profile
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/settings">
              <NavItem onClick={closeNav}>
                <img alt="img" src={Settings} />
                Settings
              </NavItem>
            </LinkContainer> */}
            <NavItem onClick={handleLogout}>
              <img alt="img" src={Logout} />
              Log Out
            </NavItem>
          </ul>
        </div>
        <div className="submenu">
          <ul>
            <li>Privacy Policy</li>
            <li>Terms & Conditions</li>
            <li>© Lift Ratio 2020.</li>
          </ul>
        </div>
      </div>

      <div className="top">
        <Link to="/">
          <img alt="img" src={Logo} />
        </Link>
        <div onClick={openNav} className="burger">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div id="main" className="App container">
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
          <div className="header small"></div>
          <Routes />
        </AppContext.Provider>
        <LinkContainer to="/recordactivity">
          <NavItem>
            <div id="addnew">
              <p className="gradient">+</p>
            </div>
          </NavItem>
        </LinkContainer>
      </div>
    </>
  );
}

export default App;
