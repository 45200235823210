import Axios from "axios";
import React, { useState, Fragment, useEffect } from "react";
import "./Record.css";
import appsettings from "../appsettings";
import Upload from "./images/icons/upload.svg"
import { onError } from "../libs/errorLib";
import { Link, useHistory, useParams } from "react-router-dom";


export default function Record() {

    const { activity } = useParams();
    let theactivity = activity;
    if (theactivity === undefined) {
        theactivity = "squat"
    }

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [weight, setWeight] = useState(0);
    const [reps, setReps] = useState(1);
    const [when, setWhen] = useState(new Date());
    const [multilift, setMultilift] = useState(false);
    const [weightData, setWeightData] = useState({});

    useEffect(() => {
        async function get() {

            try {
                const { data } = await Axios.get(`${appsettings.apiUrl()}/api/bodyweight`);
                console.log(data);

                // if the bodyweight is 0, they've not got one yet, so they need to do this before recording a weight
                if(data.currentWeight.kg === 0){
                    history.push("/first-weight");
                }

                setWeightData(data);
            } catch (e) {
                console.log(e);
                onError(e.response);
            }

            setIsLoading(false);

        }
        get();
    }, [])


    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);
        try {
            const { data } = await Axios.post(`${appsettings.apiUrl()}/api/${theactivity}Activity`, {
                kg: parseFloat(weight),
                Reps: parseInt(reps),
                LiftedOn: new Date(when)
            });
            console.log(data);
            alert("Lift Added!");
            history.push("/dashboard");

        } catch (e) {
            setIsLoading(false);
            console.log(e);
            onError(e);
        }
        setIsLoading(false);
    }


    if (isLoading) {
        return "...";
    }

    function validateForm() {
        return (
            weight && parseFloat(weight) > 10
        )

    }


    return (
        <Fragment>
            <div className="header">
                <div className="upload">
                    Upload a lift video
               <div className="uploader">
                        <img alt="upload" src={Upload} />
                    </div>
                </div>
            </div>
            <form className="recordform" onSubmit={handleSubmit}>

                <div>
                    <label>Current Body Weight </label>
                    <strong>{weightData.currentWeight.kg}</strong> kg
                    <small style={{ "padding-left": "10px" }}><Link to="/add-weight">Update</Link></small>
                </div>


                <div>
                    <label>When? (skip if today)</label>
                    <input type="date" value={when} onChange={(e) => { setWhen(e.target.value) }} />
                </div>

                <div>
                    <label>Lift Weight (kg)</label>
                    <input type="decimal" onChange={(e) => { setWeight(e.target.value) }} />
                </div>

                {/* <label>Add location</label>
                <input type="text" /> */}

                <div className="single-multi-lift">
                    Multi Lift? <a href="#" className="gradient">[i]</a>
                    <label className="switch" htmlFor="multilift">
                        <input type="checkbox" id="multilift" onClick={(e) => { setMultilift(e.target.checked); console.log(multilift); }} />
                        <span className="slider round"></span>
                    </label>
                </div>
                {
                    multilift ?

                        <div className="rep-count">
                            <div>
                                <label>Number of Reps</label>
                                <input type="number" onChange={(e) => { setReps(e.target.value) }} />
                            </div>
                        </div>
                        :
                        <></>
                }


                <button className={validateForm() ? "gradientbg" : ""} disabled={!validateForm()}>Add Lift</button>
            </form>
        </Fragment >
    );
}